const socialLink = $('.footer-section  .textpic');

socialLink.click((e) => {
  const linkedArea = e.currentTarget;
  const newUrl = $(linkedArea).find('a').attr('href');
  if (newUrl) {
    window.open(newUrl.toString());
  }
}).children().click((event) => {
  event.preventDefault();
});
