const isAllCookieAccepted = () =>
  /* eslint-disable */
   (window.Cookiebot ?
  window.Cookiebot
  && window.Cookiebot.consent
  && Object.keys(window.Cookiebot.consent).every(c => window.Cookiebot.consent[c])
  : !window.Cookiebot);
  /* eslint-enable */

const cookiebot = () => {
  const $cookiebotShowSettingsBtns = document.querySelectorAll('.js-cookiebot-settings');
  const $cookiebotPopup = document.querySelectorAll('.js-cookiebot-popup');

  if (!isAllCookieAccepted() && $cookiebotPopup.length) {
    $cookiebotPopup.forEach(($p) => { $p.classList.add('show'); });
  }

  $cookiebotShowSettingsBtns.forEach(($btn) => {
    $btn.addEventListener('click', () => window.Cookiebot && window.Cookiebot.renew && window.Cookiebot.renew());
  });
};

cookiebot();

const videoPlayer = document.querySelectorAll('.player');

function youtubeVideoInit() {
  videoPlayer.forEach((item) => {
    const videoSrc = item.getAttribute('data-youtube-video');
    const imageSrc = item.getAttribute('data-youtube-preview');
    const iframeHtml = `<iframe width="100%" height="auto" class="video" data-2click-type="video" data-src="" src=${videoSrc} frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen> </iframe> `;
    const previewImg = `<div class="video-preview__img" style="background-image: url( ${imageSrc})"><div class="video-play__btn"><span class="icon-play"></span></div></div>`;
    const videoHtml = isAllCookieAccepted() ? iframeHtml : previewImg;
    /* eslint-disable */
    item.innerHTML = videoHtml;
    /* eslint-enable */
  });
}

youtubeVideoInit();

const cookiebotOnAcceptHandler = () => {
  if (window.Cookiebot
    && window.Cookiebot.consent
    && Object.keys(window.Cookiebot.consent).every(c => window.Cookiebot.consent[c])) {
    /* eslint-disable */
    youtubeVideoInit();
    document.querySelectorAll('.js-cookiebot-popup')
      .forEach((popupEl) => popupEl.classList.remove('show'));
  }
};

window.addEventListener('CookiebotOnAccept', cookiebotOnAcceptHandler, false);
