import * as $ from 'jquery';
import 'magnific-popup';

$('body').magnificPopup({
  delegate: '.ajax-popup-link',
  type: 'ajax',
  overflowY: 'scroll',
  closeOnContentClick: false,
  closeOnBgClick: false,
  showCloseBtn: true,
  closeMarkup: '<svg class="modal-close sprite-icon sprite-search-close-icon"><use xlink:href="#sprite-search-close"/></svg>',
  callbacks: {},
});

$('body').on('click', '.modal-close', () => {
  $.magnificPopup.close();
});
