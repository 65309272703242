
$(document).ready(() => {
  const screenMinWidth = 480;
  const windowScreen = window.screen;
  const productItem = $('.product-list  .product-item');
  const productItemContainer = $('.product-list  .product-item-container');
  let highestBox = 0;

  if (productItem && windowScreen.width > screenMinWidth) {
    productItem.each(function () {
      if ($(this).height() > highestBox) {
        highestBox = $(this).height();
      }
    });

    productItem.height(highestBox);

    productItemContainer.click((e) => {
      const linkedArea = e.currentTarget;
      const newUrl = $(linkedArea).find('a').attr('href');
      const loc = window.location;
      const baseUrl = `${loc.protocol}//${loc.hostname}${loc.port ? `:${loc.port}` : ''}/`;
      const pattern = /^((http|https|ftp):\/\/)/;
      if (baseUrl && newUrl) {
        window.location = pattern.test(newUrl) ? newUrl : (baseUrl + newUrl).replace(/([^:]\/)\/+/g, '$1');
      }
    }).children().click((event) => {
      event.preventDefault();
    });
  }
});
