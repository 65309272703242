// const animateTime = 100;
// const currentPage = $('html, body');
const languageList = $('.language-list');
const languageSwitcher = $('.item-language--switcher');
const body = $('body');

languageSwitcher.click(() => {
  languageSwitcher.toggleClass('active');
  languageList.toggleClass('visible-list');

  body.toggleClass('overlay');
  body.toggleClass('language-switcher');

  // if (body.hasClass('overlay') && $('.navbar-collapse.items-list.collapse').hasClass('show')) {
  //   $('.navbar-collapse.items-list.collapse').removeClass('show');
  //   $('.navbar-toggler.collapsed').removeClass('collapsed');
  //   body.addClass('language-switcher');
  // } else {
  //   body.toggleClass('overlay');
  //   body.toggleClass('language-switcher');
  // }

  // if (languageList.hasClass('visible-list')) {
  //   currentPage.animate({ scrollTop: currentPage.height() }, animateTime);
  // }
});

// if you hover over any item-language

$('.language-list-container .item-language').mouseenter(function () {
  $(this).parent().addClass('hover');
});

$('.language-list-container .item-language').mouseleave(function () {
  $(this).parent().removeClass('hover');
});
