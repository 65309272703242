import $ from 'jquery';
import 'slick-carousel';

const screenMinWidth = 768;
const slickSliderWrap  = $('.slider');

if (slickSliderWrap.length) {
  slickSliderWrap.each(function () {
    const scope = $(this);
    const slider = $(this).find('.slider-inner');

    slider.slick({
      dots: true,
      arrows: true,
      adaptiveHeight: true,
      slidesToShow: 3,
      slidesToScroll: 3,
      rows: 0,
      autoplay: false,
      autoplaySpeed: 4999,
      swipeToSlide: true,
      infinite: true,
      prevArrow: $('.carousel-control-prev', scope).removeClass('hidden'),
      nextArrow: $('.carousel-control-next', scope).removeClass('hidden'),
      responsive: [
        {
          breakpoint: screenMinWidth,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    });
  });
}
