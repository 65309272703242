const screenMinWidth = 768;
const windowScreen = window.screen;
const tripleCarousel  = $('.triple-carousel');


if (tripleCarousel.length) {
  // tripleCarousel.carousel({
  //   interval: 10000,
  // });

  tripleCarousel.each(function () {
    const carouselItemsLength = $(this).find('.carousel-item').length;
    $(this).find('.carousel-item').each(function () {
      if ($(this).is(':first-child')) {
        $(this).addClass('active');
      }

      if (windowScreen.width > screenMinWidth) {
        let next = $(this).next();
        if (!next.length) {
          next = $(this).siblings(':first');
        }

        next.children(':first-child').clone().appendTo($(this));

        if (next.next().length > 0) {
          next.next().children(':first-child').clone().appendTo($(this));
        } else if (carouselItemsLength > 2) {
          $(this).siblings(':first').children(':first-child').clone()
            .appendTo($(this));
        }
      }
    });
  });
}
