const body = $('body');
const header = $('.header-container');
const openSearch = header.find('.open-search');
const closeSearch = header.find('.close-search');
const itemsListButton = header.find('#itemsListBtn');
const searchFormButton = header.find('#searchFormBtn');
const dropdownItems = $('.nav-item.dropdown');
const searchPageInput = $('.searchinfo .tx-solr-search-form .search-section .tx-solr-suggest');
const loaderContainer = $('.loaderContainer');
const contactForm = $('#contact_powermail_form');
const searchResultPage = $('#search-result-page');
const headerSearchField = document.querySelector('#tx-solr-search-header-form-pi-results input');
const itemDropdown = $('.nav-item.dropdown .dropdown-menu');
const firstMenuItem = header.find('.navbar .navbar-nav .nav-item:first-child > a');
let initNavMobileApplied = true;
let menuOpen = false;

const closeLangMenu = () => {
  console.log('closeLangMenu');
  body.removeClass('language-switcher');
  $('.item-language--switcher').removeClass('active');
  $('section.language-list').removeClass('visible-list');
};

function initNavMobile() {
  if (itemDropdown.length > 0) {
    $('.nav-item.dropdown')
      .find('.nav-link')
      .click(function (e) {
        e.preventDefault();
        $(this).siblings('.dropdown-menu').slideToggle(300);
      });

    $('.dropdown-items-container')
      .find('.dropdown-item-toggle')
      .click(function (e) {
        if ($(this).next('.dropdown-sub-items-container').length) {
          e.preventDefault();
          $(this).next('.dropdown-sub-items-container').toggleClass('showMobile');
        }
      });
  }
}

contactForm.submit(() => {
  contactForm.parsley().on('form:validate', (form) => {
    if (form.isValid()) {
      body.addClass('bodyWithLoader');
      loaderContainer.addClass('loading');
    }
  });
});

$('#tx-solr-search-header-form-pi-results, #tx-solr-search-form-pi-results').submit(() => {
  body.addClass('bodyWithLoader');
  loaderContainer.addClass('loading');
});

openSearch.click(() => {
  if (searchPageInput.length) {
    searchPageInput.focus();
  } else {
    $('.search-field-container').addClass('search-field-container-visible');
    headerSearchField.focus();
  }
});

closeSearch.click(() => {
  $('.search-field-container').removeClass('search-field-container-visible');
});

function triggerMenuClick(targetElement) {
  if (body.hasClass('overlay') && menuOpen) {
    body.removeClass('overlay');
  } else if (!menuOpen) {
    body.addClass('overlay');
  }
  menuOpen = !menuOpen;
  if (body.hasClass('language-switcher')) {
    closeLangMenu();
  }
  if (targetElement.attr('aria-expanded') === 'true') {
    targetElement.trigger('click');
  }
}

itemsListButton.click(() => {
  triggerMenuClick(searchFormButton);
});

searchFormButton.click(() => {
  triggerMenuClick(itemsListButton);
});

$.each(dropdownItems, function () {
  $(this).mouseover(() => {
    closeLangMenu();
    body.addClass('overlay');
  });
  $(this).mouseleave(() => body.removeClass('overlay'));
});

if (searchResultPage.length) {
  $('.toggle-items-container-mobile').addClass('search-icon-only-result-page');
  searchFormButton.before("<span class='search-icon-silver'></span>");
  searchFormButton.hide();
  $('.search-icon-only-result-page .search-icon-silver').click(() => {
    document.querySelector('#tx-solr-search-form-pi-results input.tx-solr-q').focus();
    if (itemsListButton.attr('aria-expanded') === 'true') {
      itemsListButton.trigger('click');
    }
  });
} else {
  searchFormButton.on('click', () => setTimeout(() => headerSearchField.focus(), 0)); // Need to use 'bootstrap callback', setTimeout is a temporary solution
}

$(window).on('load resize', () => {
  const windowWidth = $(window).width();
  if (windowWidth < 992 && initNavMobileApplied) {
    initNavMobileApplied = false;
    initNavMobile();
  } else if (windowWidth >= 992 && !initNavMobileApplied) {
    initNavMobileApplied = true;
  }

  firstMenuItem.click((e) => {
    e.preventDefault();
  });
});
