require('./elements/keyvisual');
require('./elements/header');
require('./elements/language_switcher');
require('./elements/breadcrumb');
require('./elements/product_details_slider');
require('./elements/carousel_section');
require('./elements/slider');
require('./elements/product_list');
require('./elements/modal');
require('./elements/contact_form');
require('./elements/google_map');
require('./elements/product_banner');
require('./elements/footer');
require('./elements/video2click');
