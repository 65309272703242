const breadcrumb = $('.breadcrumb-wr');
const anchors = breadcrumb.find('.breadcrumb-item a');
const bannerButton = $('.product-banner .blue-button');
const contactFormLink = $('.product-details-slider-wr .description .blue-button, .product-banner-popup-link');
const retreat = breadcrumb.height() * (breadcrumb.hasClass('scrolled') ? 1 : 2);
const screenMinWidth = 768;
const windowScreen = window.screen;

contactFormLink.click((event) => {
  event.preventDefault();
  $('html, body').animate({
    scrollTop: windowScreen.width > screenMinWidth ? $('.contact-person-section').offset().top - retreat : $('.contact-person-section').offset().top,
  }, 'slow');
});

bannerButton.click(() => {
  if (windowScreen.width < screenMinWidth) {
    $('html, body').animate({ scrollTop:  bannerButton.offset().top - 100 }, 'slow');
  }
});

$(window).scroll(() => {
  if (breadcrumb) {
    breadcrumb.toggleClass('scrolled', $(window).scrollTop() > breadcrumb.height());
  }
});

if (breadcrumb) {
  $.each(anchors, function () {
    $(this).click((event) => {
      const href = $(event.target).attr('href');
      const id = href.substr(href.indexOf('#'));
      if (this.hash !== '') {
        event.preventDefault();

        $('html, body').animate({
          scrollTop: $(id).offset().top - retreat,
        }, 'slow');
      }
    });
  });
}
