/* global google */
const mapContainer = $('#map');

const setMarkers = (map) => {
  const offices = mapContainer.data('offices');
  const image = {
    url: mapContainer.data('icon'),
    // for dev
    // url: '../../Icons/location-icon.svg',
    size: new google.maps.Size(20, 32),
    origin: new google.maps.Point(0, 0),
    anchor: new google.maps.Point(0, 32),
  };
  const bounds = new google.maps.LatLngBounds();
  $.each(offices, (index) => {
    const {
      locations,
      address,
      phone,
      mail,
      lat,
      lng,
    } = offices[index];
    const content = `${locations.map(item => item.name)} <br> ${address} <br> ${phone} <br> ${mail}`;
    const loc = new google.maps.LatLng(lat, lng);
    const marker = new google.maps.Marker({
      position: { lat, lng },
      map,
      icon: image,
    });
    const infowindow = new google.maps.InfoWindow();
    google.maps.event.addListener(marker, 'click', (() => () => {
      infowindow.setContent(content);
      infowindow.open(map, marker);
    })(marker, content, infowindow));
    bounds.extend(loc);

    google.maps.event.addListener(infowindow, 'closeclick', () => {
      map.fitBounds(bounds);
    });
  });
  map.fitBounds(bounds);
};

window.initMap = () => {
  if (mapContainer.length) {
    const map = new google.maps.Map(mapContainer.get(0));
    setMarkers(map);
  }
};
