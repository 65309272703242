const leftSide = $('.custom-keyvisual-animated .left-side-container');
const rightSide = $('.custom-keyvisual-animated  .right-side-container');
const centerSide = $('.custom-keyvisual-animated  .centered-container');
const textBlockDesktop = $('.custom-keyvisual-animated  .text-block');
const textBlock = $('.custom-keyvisual  .text-block');
let highestBox = 0;

textBlockDesktop.each(function () {
  if ($(this).height() > highestBox) {
    highestBox = $(this).height();
  }
});

textBlockDesktop.height(highestBox);

textBlock.click((e) => {
  const linkedArea = e.currentTarget;
  const newUrl = $(linkedArea).find('a').attr('href');
  const loc = window.location;
  const baseUrl = `${loc.protocol}//${loc.hostname}${loc.port ? `:${loc.port}` : ''}/`;
  if (baseUrl && newUrl) {
    window.location = (baseUrl + newUrl).replace(/([^:]\/)\/+/g, '$1');
  }
}).children().click((event) => {
  event.preventDefault();
});

leftSide.hover(() => {
  $('.custom-keyvisual-animated ').toggleClass('left-side-active');
});

rightSide.hover(() => {
  $('.custom-keyvisual-animated ').toggleClass('right-side-active');
});

centerSide.hover(() => {
  $('.custom-keyvisual-animated').toggleClass('center-side-active');
});
